import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import authSelectors from "src/modules/auth/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import PermissionChecker from "src/modules/auth/permissionChecker";
import actions from "src/modules/layout/layoutActions";
import layoutSelectors from "src/modules/layout/layoutSelectors";
import menus from "src/view/menus";
import clsx from "clsx";
import {
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@material-ui/core";

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		background:  'linear-gradient(to right, #384048, #00a695)'
	},
	active: {
	},
	toolbar: theme.mixins.toolbar,
	listItemDisabled: {
		opacity: 0.5,
	},
	listItem: {
		display: 'flex',
    alignItems: 'center',
		gap: '6px',
	},
	root: {
		fontSize: '25px',
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		padding: '80px 0',
	},
}));

function Menu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const resourcesCode = useSelector(authSelectors.selectResourcesCode);
  const menuVisible = useSelector(layoutSelectors.selectMenuVisible);
  const permissionChecker = new PermissionChecker(currentUser);

  useLayoutEffect(() => {
    const toggleMenuOnResize = () => {
      (window as any).innerWidth < 576 && dispatch(actions.doHideMenu());
    };

    toggleMenuOnResize();

    (window as any).addEventListener("resize", toggleMenuOnResize);

    return () => {
      (window as any).removeEventListener("resize", toggleMenuOnResize);
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.doShowMenu());
  }, []);

  const selectedKeys = () => {
    const url = props.url;

    const match = menus.find((option) => {
      if (option.exact) {
        return url === option.path;
      }

      return url === option.path || url.startsWith(option.path + "/");
    });

    if (match) {
      return [match.path];
    }

    return [];
  };

  const match = (permission, resources) => {
    //return permissionChecker.match(permission) && resourcesCode.some(code => resources.includes(code));
    return resourcesCode.some(code => resources.includes(code));
  };

  const CustomRouterLink = (props) => (
    <div
      style={{
        flexGrow: 1,
      }}
    >
      <Link
        style={{
          textDecoration: "none",
          color: "inherit",
          width: "100%",
        }}
        {...props}
      />
    </div>
  );

  return (
    <>
      {menuVisible ? (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          anchor="left"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.toolbar}></div>
          <List className={classes.list}>
            {menus
              .filter((menu) => match(menu.permissionRequired, menu.resource))
              .map((menu) => (
                  <CustomRouterLink key={menu.path} to={menu.path}>
                    <ListItem
                      button
                      selected={selectedKeys().includes(menu.path)}
                      className={clsx({
                        [classes.listItem]: true,
                      })}
                    >
                      <ListItemIcon
                        style={{
                          color: "#FFFF",
                          fontWeight: "bold",
                          minWidth: "0",
                          width: "25px",
                          height: "25px",
                        }}
                        className={clsx({
                          [classes.active]: selectedKeys().includes(menu.path),
                        })}
                      >
                        {menu.icon}
                      </ListItemIcon>
                      <ListItemText
                        className={clsx({
                          [classes.active]: selectedKeys().includes(menu.path),
                        })}
                        primary={
                          <Typography
                            style={{
                              color: "#FFFF",
                              fontWeight: 400,
                              fontSize: "16px",
                            }}
                          >
                            {menu.label}
                          </Typography>
                        }
                      ></ListItemText>
                    </ListItem>
                  </CustomRouterLink>
                )
              )}
          </List>
          <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', width: '100%', height: '-webkit-fill-available'}}>
          <img
        src="/images/logo-blanco-murchison.svg"
        alt="Logo Terminal Zárate"
        style={{
          width: '80%'
        }}
      /> 
      </Box>
        </Drawer>
      ) : null}
    </>
  );
}

export default Menu;
